import React, { useEffect, useRef, useState, useCallback } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { noop } from "lodash";
import ReactPlayer from "react-player";
import IconWithLabel from "./IconWithLabel";
import "../../styles/EventDetailsPopover.scss";

const DualVideoPlayer = ({ mainVideoUrl, secondaryVideoUrl }) => {
  const [videoError, setVideoError] = useState();
  const [showSecondary, setShowSecondary] = useState(true);
  const [playing, setPlaying] = useState(false);

  const volumeRef = useRef(0.5);
  const mutedRef = useRef(false);
  const mainPlayer = useRef(null);
  const secondaryPlayer = useRef(null);

  const handleFootageError = () => {
    setVideoError("Video is unavailible");
  };

  const activePlayerVolumeChangeHandler = (e) => {
    const activeInternalPlayer = mainPlayer.current.props.active
      ? mainPlayer.current.getInternalPlayer()
      : secondaryPlayer.current.getInternalPlayer();
    if (e.target == activeInternalPlayer) {
      volumeRef.current = e.target.volume;
      mutedRef.current = e.target.muted;
    }
  };

  //check if seconds are less than half a second apart
  const isCloseTo = (seconds, target) => {
    return Math.abs(seconds - target) < 0.5;
  };
  const getPlayerConfig = () => {
    return {
      xhrSetup: function (xhr, url) {
        xhr.withCredentials = true; // do send cookies
        xhr.setRequestHeader(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        );
      },
    };
  };
  const handleMainProgress = ({ playedSeconds }, isControled, opositeRef) => {
    if (
      isControled &&
      opositeRef.current &&
      !isCloseTo(playedSeconds, secondaryPlayer.current.getCurrentTime())
    ) {
      opositeRef.current.seekTo(playedSeconds);
    }
  };
  const handleSeek = (seconds, isControled, opositeRef) => {
    if (isControled && opositeRef.current) {
      opositeRef.current.seekTo(seconds);
    }
  };

  const [framesOptions, setFramesOptions] = useState({
    main: {
      style: {
        width: "unset",
        height: "100%",
        objectFit: "contain",
        position: "relative",
      },
      config: getPlayerConfig(),
      controls: true,
      progressInterval: 500,
      onError: handleFootageError,
      onPlay: () => setPlaying(true),
      onPause: () => setPlaying(false),
      onClick: noop,
      active: true,
    },
    secondary: {
      style: {
        width: "33.33%",
        height: "auto",
        top: 0,
        zIndex: 9,
        objectFit: "contain",
        position: "absolute",
        cursor: "pointer",
        right: 0,
      },
      config: getPlayerConfig(),
      controls: false,
      onError: () => setShowSecondary(false),
      onClick: (e) => {
        e.preventDefault();
        replace();
      },
    },
    active: false,
  });

  const replace = () => {
    setFramesOptions((prev) => ({
      main: prev.secondary,
      secondary: prev.main,
    }));
  };

  const setVolumeAndMutedInternal = useCallback(
    (activeInternalPlayer, inactiveInternalPlayer) => {
      if (!activeInternalPlayer || !inactiveInternalPlayer) return;
      activeInternalPlayer.volume = volumeRef.current;
      activeInternalPlayer.muted = mutedRef.current;
      inactiveInternalPlayer.volume = 0;
      activeInternalPlayer.addEventListener(
        "volumechange",
        activePlayerVolumeChangeHandler
      );
    },
    []
  );

  useEffect(() => {
    if (
      mainPlayer &&
      mainPlayer.current &&
      mainPlayer.current.getInternalPlayer()
    ) {
      const videoElement = mainPlayer.current.getInternalPlayer();
      videoElement.addEventListener(
        "volumechange",
        activePlayerVolumeChangeHandler
      );
    }
    return () =>
      mainPlayer.current
        ?.getInternalPlayer()
        ?.removeEventListener("volumechange", activePlayerVolumeChangeHandler);
  }, [mainPlayer.current]);

  useEffect(() => {
    mainPlayer.current &&
      (mainPlayer.current.wrapper.onClick = framesOptions.main.onClick);
    secondaryPlayer.current &&
      (secondaryPlayer.current.wrapper.onClick =
        framesOptions.secondary.onClick);
    if (mainPlayer.current?.props.active) {
      setVolumeAndMutedInternal(
        mainPlayer.current?.getInternalPlayer(),
        secondaryPlayer.current?.getInternalPlayer()
      );
    } else {
      setVolumeAndMutedInternal(
        secondaryPlayer.current?.getInternalPlayer(),
        mainPlayer.current?.getInternalPlayer()
      );
    }
  }, [framesOptions, setVolumeAndMutedInternal]);

  return (
    <div className="video-container">
      {!videoError && mainVideoUrl ? (
        <>
          <ReactPlayer
            {...framesOptions.main}
            width={framesOptions.main.style.width}
            height={framesOptions.main.style.height}
            url={mainVideoUrl}
            playing={playing}
            ref={mainPlayer}
            onProgress={({ playedSeconds }) =>
              handleMainProgress(
                { playedSeconds },
                framesOptions.main.controls,
                secondaryPlayer
              )
            }
            onSeek={(seconds) =>
              handleSeek(seconds, framesOptions.main.controls, secondaryPlayer)
            }
            onClick={(e) => {
              mainPlayer.current.wrapper.onClick(e);
            }}
          />
          {Boolean(secondaryVideoUrl && showSecondary) && (
            <ReactPlayer
              {...framesOptions.secondary}
              width={framesOptions.secondary.style.width}
              height={framesOptions.secondary.style.height}
              ref={secondaryPlayer}
              playing={playing}
              url={secondaryVideoUrl}
              onSeek={(seconds) =>
                handleSeek(
                  seconds,
                  framesOptions.secondary.controls,
                  mainPlayer
                )
              }
              onProgress={({ playedSeconds }) =>
                handleMainProgress(
                  { playedSeconds },
                  framesOptions.secondary.controls,
                  mainPlayer
                )
              }
              onClick={(e) => {
                secondaryPlayer.current.wrapper.onClick(e);
              }}
              volume={0}
              muted={true}
            />
          )}
        </>
      ) : (
        <IconWithLabel label={videoError} color="#b30000">
          <BiErrorCircle />
        </IconWithLabel>
      )}
    </div>
  );
};

export default DualVideoPlayer;
