const eventsSchema = [
    { label: "deviceID", key: "deviceID" },
    { label: "messageID", key: "messageID" },
    { label: "selfIP", key: "selfIP" },
    { label: "tripID", key: "tripID" },
    { label: "driverID", key: "driverID" },
    { label: "eventDateTime", key: "eventDateTime" },
    { label: "lastKnownLocationLong", key: "lastKnownLocationLong" },
    { label: "lastKnownLocationLat", key: "lastKnownLocationLat" },
    { label: "lastKnownStreetAddress", key: "lastKnownStreetAddress" },
    { label: "locationTime", key: "locationTime" },
    { label: "speed (km/h)", key: "speed" },
    { label: "headingAngle", key: "headingAngle" },
    { label: "locationQuality", key: "locationQuality" },
    { label: "extPower", key: "extPower" },
    { label: "internalBatteryPower", key: "internalBatteryPower" },
    { label: "iOArrayStatus (IGN)", key: "iOArrayStatus.IGN" },
    { label: "iOArrayStatus (GPI)", key: "iOArrayStatus.GPI" },
    { label: "iOArrayStatus (GPO)", key: "iOArrayStatus.GPO" },
    { label: "iOArrayStatus (GPIO)", key: "iOArrayStatus.GPIO" },
    { label: "eventType", key: "eventType" },
    { label: "eventAttr1", key: "eventAttr1" },
    { label: "cabinImageCount", key: "cabinImageCount" },
    { label: "cabinVideoCount", key: "cabinVideoCount" },
    { label: "roadVideoCount", key: "roadVideoCount" },
    { label: "roadImageCount", key: "roadImageCount" }
];

const devicesSchema = [
    { label: "id", key: "id" },
    { label: "ownerEmail", key: "ownerEmail" },
    { label: "vehicleId", key: "vehicleId" },
    { label: "deviceID", key: "deviceID" },
    { label: "deviceType", key: "deviceType" },
    { label: "messageID", key: "messageID" },
    { label: "selfIP", key: "selfIP" },
    { label: "tripID", key: "tripID" },
    { label: "driverID", key: "driverID" },
    { label: "eventDateTime", key: "eventDateTime" },
    { label: "lastKnownLocationLong", key: "lastKnownLocationLong" },
    { label: "lastKnownLocationLat", key: "lastKnownLocationLat" },
    { label: "lastKnownStreetAddress", key: "lastKnownStreetAddress" },
    { label: "locationTime", key: "locationTime" },
    { label: "speed", key: "speed" },
    { label: "headingAngle", key: "headingAngle" },
    { label: "locationQuality", key: "locationQuality" },
    { label: "extPower", key: "extPower" },
    { label: "internalBatteryPower", key: "internalBatteryPower" },
    { label: "iOArrayStatus (IGN)", key: "iOArrayStatus.IGN" },
    { label: "iOArrayStatus (GPI)", key: "iOArrayStatus.GPI" },
    { label: "iOArrayStatus (GPO)", key: "iOArrayStatus.GPO" },
    { label: "iOArrayStatus (GPIO)", key: "iOArrayStatus.GPIO" },
    { label: "eventType", key: "eventType" },
    { label: "eventAttr1", key: "eventAttr1" },
    { label: "hasImage", key: "hasImage" },
    { label: "hasFootage", key: "hasFootage" }
];

const usersSchema = [
    { label: "userName", key: "userName" },
    { label: "email", key: "email" },
    { label: "lastAccessed", key: "lastAccessed" },
    { label: "role", key: "role" }
];

export const getCsvHeaders = (pageName) => {
    const lowercasePageName = pageName.toLowerCase();
    if (lowercasePageName === 'events') {
        return eventsSchema;
    } else if (lowercasePageName === 'devices') {
        return devicesSchema;
    } else if (lowercasePageName === 'users') {
        return usersSchema;
    } else return;
}